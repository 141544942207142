import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'

import { UserProvider } from './contexts/UserProvider'
import { TableProvider } from './contexts/TableProvider'

ReactDOM.render(
  <Provider store={store}>
    <UserProvider>
      <TableProvider>
        <App />
      </TableProvider>
    </UserProvider>
  </Provider>,

  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
