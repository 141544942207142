import React, { useContext, Suspense } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import './scss/style.scss'

import { UserContext } from './contexts/UserProvider'

const loading = <div className="bg-light"></div>

// Containers
const Layout = React.lazy(() => import('./layout/Layout'))

// Pages
const Login = React.lazy(() => import('./pages/login/Login'))
const Register = React.lazy(() => import('./pages/register/Register'))
const Confirm = React.lazy(() => import('./pages/register/Confirm'))
const Page404 = React.lazy(() => import('./pages/page404/Page404'))
const Page500 = React.lazy(() => import('./pages/page500/Page500'))

const App = () => {
  const { isAuthenticated, isReady } = useContext(UserContext)
  return (
    <Suspense fallback={<div style={{ backgroundImage: `url('${loading}')` }} />}>
      <BrowserRouter>
        {isReady && (
          <Routes>
            <Route path="/confirmuser" name="Confirm User" element={<Confirm />} />
            <Route
              exact
              path="/login"
              name="Login Page"
              element={isAuthenticated ? <Navigate replace to="/" /> : <Login />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              element={isAuthenticated ? <Navigate replace to="/" /> : <Register />}
            />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={isAuthenticated ? <Layout /> : <Login />} />
          </Routes>
        )}
      </BrowserRouter>
    </Suspense>
  )
}

export default App
