import React, { useState, useEffect, useContext, createContext } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import { UserContext } from 'src/contexts/UserProvider'

const TableContext = createContext()

const TableProvider = (props) => {
  const { IP, email, connections, defaultConnection, setDefaultConnection } =
    useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const [targetPool, setTargetPool] = useState()
  const [target, setTarget] = useState()
  const [headerset, setHeaderset] = useState([])
  const [columnset, setColumnset] = useState([])
  const [recordset, setRecordset] = useState([])
  const [actionset, setActionset] = useState([])
  const [amountset, setAmountset] = useState()
  const [carryover, setCarryover] = useState()
  const [match, setMatch] = useState('AND')
  const [offset, setOffset] = useState(1)
  const [limit, setLimit] = useState(100)
  const [pages, setPages] = useState(1)
  const [count, setCount] = useState(0)
  const [total, setTotal] = useState(0)
  const [travelling, setTravelling] = useState(false)
  const [travels, setTravels] = useState([])
  const [current, setCurrent] = useState(-1)

  const fetch = async () => {
    try {
      const conditions = columnset
        .filter((e) => e.value)
        .map((e) => {
          return { type: e.type, column: e.column, operator: e.operator, value: e.value }
        })

      console.log('# columnset: ', columnset, ', # length: ', columnset.length)
      console.log('# target: ', target)

      const orders =
        columnset.length > 0
          ? columnset
              .filter((e) => e.order)
              .sort((a, b) => a.priority - b.priority)
              .map((e) => {
                return { column: e.column, order: e.order }
              })
          : target.columns

      console.log('# orders: ', orders)

      const url = `${process.env.REACT_APP_API}/tables`
      const params = {
        user: { ip: IP, email: email },
        connection: defaultConnection,
        target: target,
        match: match,
        conditions: carryover || conditions,
        orders: orders,
        paging: {
          offset: (offset - 1) * limit,
          limit: limit,
        },
      }

      console.log('# params: ', params)

      const { data } = await axios.get(url, {
        params: params,
      })
      console.log('# data: ', data)

      Cookies.set('connection', JSON.stringify(defaultConnection), { expires: 7 })

      setPages(data.amountset ? Math.ceil(data.amountset.Total / limit) : 1)
      setTotal(data.amountset ? data.amountset.Total : 0)
      setCount(0)

      const headers = data.headerset
      const columns = data.columnset.map((column) => {
        column.operator = column.type === 'string' ? '%%' : '=='
        column.order = ''

        const action = data.actionset.find((e) => e.column === column.column)
        if (action && action.display === 'image') {
          column.inactive = true
        }

        if (carryover) {
          const element = carryover.find((e) => e.column === column.column)
          if (element) {
            column.operator = '=='
            column.value = element.value
          }
        }
        return column
      })

      if (columnset.length === 0) {
        target.columns.forEach((e) => {
          const column = columns.find((col) => col.column === e.column)
          if (column) {
            column.order = e.order
          }
        })
        setColumnset([...columns])
        setHeaderset([...headers])
      }

      if (!travelling) {
        setTravels([
          ...travels.slice(0, current + 1),
          {
            index: current + 1,
            target: target,
            columnset: structuredClone(columnset),
            match: match,
            offset: offset,
            limit: limit,
            pages: pages,
            total: total,
          },
        ])
        setCurrent(current + 1)
      }

      setRecordset(data.recordset)
      setActionset(data.actionset)
      setAmountset(data.amountset)
      setCarryover()
      setTravelling(false)
      setLoading(false)
    } catch (error) {
      console.log('error: ', error)
    }
  }

  const fetchTargets = async () => {
    try {
      const url = `${process.env.REACT_APP_API}/tables/targets`
      const { data } = await axios.get(url, { params: { connection: defaultConnection } })
      console.log('# targets: ', data.targets)
      setTargetPool(data.targets)
    } catch (error) {
      console.log('error: ', error)
    }
  }

  useEffect(() => {
    if (targetPool) {
      setTarget(targetPool[0])
    }
  }, [targetPool])

  useEffect(() => {
    if (defaultConnection && target && loading) {
      fetch()
    }
  }, [defaultConnection, target, loading])

  useEffect(() => {
    if (!loading) {
      setLoading(true)
      setOffset(1)
    }
  }, [target, limit])

  useEffect(() => {
    if (!loading) {
      setLoading(true)
    }
  }, [offset])

  useEffect(() => {
    if (defaultConnection) {
      fetchTargets()
      setTravels([])
      setCurrent(-1)
    }
  }, [defaultConnection])

  useEffect(() => {
    if (connections) {
      const connection = Cookies.get('connection')
      if (connection) {
        const object = JSON.parse(connection)
        setDefaultConnection(object)
      } else {
        setDefaultConnection(connections[0])
      }
    }
  }, [connections])

  return (
    <TableContext.Provider
      value={{
        loading,
        setLoading,
        targetPool,
        setTargetPool,
        target,
        setTarget,
        columnset,
        setColumnset,
        headerset,
        setHeaderset,
        recordset,
        setRecordset,
        actionset,
        setActionset,
        amountset,
        setAmountset,
        carryover,
        setCarryover,
        match,
        setMatch,
        offset,
        setOffset,
        limit,
        setLimit,
        pages,
        setPages,
        count,
        setCount,
        total,
        setTotal,
        travels,
        current,
        setCurrent,
        travelling,
        setTravelling,
      }}
    >
      {props.children}
    </TableContext.Provider>
  )
}

TableProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export { TableProvider, TableContext }
